.hljs {
    display: block;
    overflow: auto;
    color: #333;
    padding: 4px;
    @apply pl-12;
    background: #282a36;
    font-size: 1.0625rem;
    line-height: 2;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 5px 0;
    font-family: "Monaco", "Consolas", "Liberation Mono", "Courier New", "monospace";
    font-weight: 400;
}

.hljs-built_in,
.hljs-selector-tag,
.hljs-section,
.hljs-link {
    color: #8be9fd;
}

.hljs-keyword {
    color: #ff79c6;
}

.hljs,
.hljs-subst {
    color: #f8f8f2;
}

.hljs-title {
    color: #50fa7b;
}

.hljs-string,
.hljs-meta,
.hljs-name,
.hljs-type,
.hljs-attr,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable {
    color: #f1fa8c;
}

.hljs-comment,
.hljs-quote,
.hljs-deletion {
    color: #6272a4;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-title,
.hljs-section,
.hljs-doctag,
.hljs-type,
.hljs-name,
.hljs-strong {
    font-weight: bold;
}

.hljs-literal,
.hljs-number {
    color: #bd93f9;
}

.hljs-emphasis {
    font-style: italic;
}

.diff-addition {
    background-color: #4e9a0640;
}
.diff-subtraction {
    background-color: #cc000040;
}
