/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700;900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@400;700;900&display=swap");

@tailwind base;

@tailwind components;

@tailwind utilities;

[x-cloak] {
    display: none;
}

@import "./home.css";
@import "./blog.css";
@import "./docs.css";
@import "./colors.css";
@import "./hljs.css";
