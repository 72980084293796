#article-body h1 {
    @apply text-2xl mt-6 mb-2 font-black;
}

#article-body h2 {
    @apply text-xl mt-6 mb-2 font-black;
}

#article-body h3 {
    @apply text-lg mt-6 mb-2 font-black;
}

#article-body h4 {
    @apply text-md mt-6 mb-2 font-black;
}

#article-body h5 {
    @apply text-base mt-6 mb-2 font-bold;
}

#article-body h6 {
    @apply text-base mt-6 mb-2 font-bold;
}

@screen md {
    #article-body h1 {
        @apply text-4xl
    }

    #article-body h2 {
        @apply text-3xl
    }

    #article-body h3 {
        @apply text-2xl
    }

    #article-body h4 {
        @apply text-xl
    }

    #article-body h5,
    #article-body h6 {
        @apply text-lg
    }
}

#article-body p {
    @apply py-4;
}

#article-body>p>code {
    @apply text-awssat bg-gray-300 font-mono px-1 rounded;
}

#article-body>:not(pre) {
    @apply break-words;
}

#article-body>hr {
 @apply my-6 border-dashed border-gray-400;
}

#article-body blockquote {
    @apply relative pl-4 py-2 border-l-4 border-gray-700 bg-gray-200;
}


#article-body>blockquote {
    @apply pr-8;
}

#article-body>blockquote::before {
    content: '\0022';
    @apply absolute top-0 right-0 font-bold text-5xl text-gray-600 m-2;
}

#article-body .footnotes ol {
    @apply list-decimal bg-yellow-100 p-2 text-sm leading-loose;
}

#article-body .footnotes li {
    @apply ml-3;
}

#article-body .footnotes p {
    @apply py-0;
}

#article-body .twitter-tweet {
    @apply mx-auto clear-both;
}

#article-body img,
#article-body video,
#article-body embed,
#article-body iframe,
#article-body object,
#article-body svg {
    @apply block mx-auto max-w-full align-middle;
}

#article-body>ul {
    @apply list-disc ml-8;
}

#article-body>ol {
    @apply list-decimal ml-8;
}

#article-body>ol p {
    @apply py-0;
}

#article-body>table {
    @apply table-auto mx-auto clear-both;
}

#article-body>table>thead>tr>th {
    @apply px-4 py-2
}

#article-body>table>tbody>tr>td {
    @apply border px-4 py-2
}

#article-body .heading-permalink {
    @apply text-awssat mr-2;
}

#article-body a {
    @apply text-awssat;
}

#article-body a:hover {
    @apply underline
}

#article-body a:visited {
    @apply text-purple-600
}



.dark-mode #article-body blockquote {
    @apply bg-gray-700 border-gray-500;
}

.dark-mode #article-body .footnotes ol {
    @apply bg-yellow-500 text-gray-700;
}

.dark-mode #article-body a {
    @apply text-awssat-light;
}
